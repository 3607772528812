@import "src/mixins";
@import "src/zindex";

.nav-urgent-request {
  position: absolute;
  bottom: 0;
  left: 8px;
  z-index: map-get($zIndex, navUrgentRequest);
  width: 264px !important;
  background-color: var(--nav-urgent-request-bg);

  &::before {
    position: absolute;
    content: "";
    left: -8px;
    bottom: 22px;
    width: 8px;
    height: 16px;
    border-color: transparent;
    border-style: solid;
    border-width: 8px 8px 8px 0;
    border-right-color: var(--nav-urgent-request-bg);
  }

  .small {
    font-size: 1.2rem;
  }

  p {
    font-size: 1.6rem;
    @include color(var(--nav-urgent-request-text-color));
  }

  .label {
    @include color(var(--nav-urgent-request-label-color));
  }

  header {
    padding: 25px 18px 18px;
    background-color: var(--nav-urgent-request-header-bg);

    p {
      @include color(var(--nav-urgent-request-header-color));
      margin: 0;
      font-weight: bold;
    }
  }
}
