@import "src/colors";

.leaflet-container {
  width: auto;
  height: 100%;
  min-height: 400px;
}

.my-map {
  height: 100%;

  .search-control-icon-button {
    padding: 12px 8px 4px;
  }

  .search-control-wrap {
    --primary-color: var(--map-control-icon-color) !important;
    --main-background-color: var(--map-control-bg) !important;
    --icon-button-max-size: 18px !important;
    --icon-width: 48px !important;
    --icon-height: 48px !important;
    --active-height: 48px !important;
    box-shadow: none;
    border-radius: 2px;

    .search-control {
      .search-control-icon-button {
        svg {
          max-height: 24px;
          max-width: 24px;
        }
      }
    }
  }

  .leaflet-control-layers {
    border: 1px solid var(--white);
    border-radius: 2px;
    background: var(--map-control-layers-bg);
    color: var(--map-control-text);

    .leaflet-control-layers-toggle {
      width: 48px;
      height: 48px;
      background-image: url('img/maps.svg');
      background-size: 60%;
      filter: invert(100%);
    }
  }


  .leaflet-right {
    .leaflet-control {
      margin-right: 0;
    }
  }

  .leaflet-bar {
    box-shadow: none;

    a {
      width: 48px;
      height: 48px;
      padding: 12px;
      color: var(--map-control-icon-color);
      fill: var(--map-control-icon-color);
      background-color: var(--map-control-bg);

      &:first-child {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }

      &:last-child {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }

    button {
      background-color: rgba(3, 15, 64, 0.3);
      border: none;
      border-radius: 2px;
      width: 48px;
      height: 48px;
      padding: 12px;

      svg {
        fill: var(--map-control-icon-color);
      }
    }

    .button-icon {
      font-size: 1.8rem;
    }
  }
}

.request-icon {
  &__closed {
    fill: var(--status-closed);
  }

  &__closed-rejected {
    fill: var(--status-closed-rejected);
  }

  &__done {
    fill: var(--status-done);
  }

  &__intervention {
    fill: var(--request-icon-bg-intervention);
  }

  &__ongoing {
    fill: var(--status-ongoing);
  }

  &__planned {
    fill: var(--status-planned);
  }

  &__to-plan {
    fill: var(--status-to-plan);
  }

  &__to-qualify {
    fill: var(--status-to-qualify);
  }

  &__to-specify {
    fill: var(--status-to-specify);
  }
}
