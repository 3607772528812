.detail-block3 {
  .dl-attachment {
    font-size: 1.4rem;
    cursor: pointer;
  }

  .timeline-item {
    &.timeline-start {
      .timeline-badge {
        background-color: var(--pink);
      }
    }

    &.timeline-end {
      .timeline-badge {
        background-color: var(--white);
        border: 3px solid var(--pink);
      }
    }

    &.timeline-processing-duration {
      .timeline-badge {
        background-color: var(--processing-duration);
      }
    }

    &.timeline-comment {
      position: relative;

      .timeline-badge {
        background-color: var(--comment);
      }

      .toggle {
        position: relative;
        top: -2px;
        float: right;
        cursor: pointer;
      }
    }

    &.timeline-attachment {
      .timeline-badge {
        background-color: var(--gray-dark);
      }
    }
  }

  .entries {
    .timeline-comment:hover {
      background-color: var(--comment-background);
    }
  }

  .comment {
    border-left: var(--comment) 1px solid;
    color: var(--comment);
    margin-left: 190px;
    margin-bottom: 21px;
    padding: 2px 0;
  }
}
