@import "mixins";

.form-control {
  font-size: 1.4rem;
}

select {
  &.form-control {
    height: 48px;
  }
}
