$zIndex: (
  indexZero: 0,
  base: 1,
  requestFilter: 10,
  pdfExport:10,
  mainOverlay: 900,
  navUrgentRequest: 1000,
  mapFilter: 1000,
  header : 5000,
  loading: 10000,
);

.z-base {
  z-index: map-get($zIndex, base);
}

.z-request-filter {
  z-index: map-get($zIndex, requestFilter);
}

.z-pdf-export {
  z-index: map-get($zIndex, pdfExport);
}
.z-step-actions {
  z-index: map-get($zIndex, indexZero);
}
