$statusList : (planned: "status-planned", done: "status-done", ongoing: "status-ongoing");
.infobox {
    &.intervention{
        padding: 5px;
        margin-bottom: 10px;
        &> div{
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .light-text{
            color:var(--detail-text-light);
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.5px;
            line-height: 12px;
        }
    }
    &.intervention{
        @each $key,$value in $statusList {
            &.status-#{$key}:before
            {
                content: "";
                border: 2px solid;
                border-color: var(--#{$value});
            }
            .status-sticker-#{$key}{
                background-color: var(--#{$value});
            }
          }
    }
}
