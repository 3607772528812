.attachment-step5 {
  cursor: pointer;

  i {
    float: left;
    padding-right: 1.5em;
    font-size: 1.5rem;
    color: var(--toulouse-grey2);
  }
}
