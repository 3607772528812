@import "src/colors";

.map-legend {
  position: absolute;
  right: 57px;
  top: 0;
  width: 200px;

  .box-shadow {
    padding: 12px 24px;
  }

  .list-group {
    .list-group-item {
      padding: 4px 0;
      border: none;
      font-size: 1.6rem;
    }
  }

  .legend-title {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: var(--map-legend-title-text-color);
  }

  .legend-marker {
    display: inline-block;
    width: 30px;
    height: 33px;
    margin-right: 14px;
  }
}

