.caption{
  font-size: 1.2rem;
  color: var(--notification-text-secondary-color);
}

.notifications-modal-subtitle{
  margin-top: 25px;
  padding: 10px 24px;
  font-size: 1.5rem;
  color: var(--notification-text-secondary-color);
}

.box {
  padding: 24px;

  &.box-table-lookalike {
    color: var(--primary);
    padding: 0;
    font-size: 1.6rem;

    .box-header {
      background-color: var(--notification-box-header-background-color);
      padding: 10px 24px;
    }
    .box-body {
      padding: 24px;
    }
  }

  &.box-no-padding {
    padding: 0;
  }
  &.box-border {
    border: 1px solid var(--notification-box-border-color);
  }
}

.notifications-send-container {
  padding: 20px;
}