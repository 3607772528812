.leaflet-popup-content-wrapper{
    border-radius: 0;
    .leaflet-popup-content{
        margin: 0;
        width: 26.1rem;
    }
}
.leaflet-container {
    z-index: 0;
}
