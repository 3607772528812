.request-comment,
.request-comment:active,
.request-comment:focus,
.request-comment:hover {
  border: none;
  box-shadow: none;
  resize: none;
  box-sizing: content-box;
}
.nav.nav-tabs.comments-tab{
  border-bottom: 2px solid var(--comments-tab-border-color);
  background:var(--white);
  a {
    color: var(--comments-tab-title);
    font-family: 'DINPro-Medium';
    font-size: 1.4rem;
    text-align: center;
  }
  a.active{
    border-bottom: none;
    border-right: 2px solid var(--comments-tab-border-color);
    background: var(--comments-tab-background-active);
  }
  a.active:not(:first-child){
    border-left: 2px solid var(--comments-tab-border-color);
  }
}
