@import "src/mixins.scss";

.pdf-container {
  .export {
    @include background(none);
    @include color(var(--primary));
    box-shadow: none;
    font-size: 14px;
    font-weight: 500;
  }

  .export:hover {
    @include color(var(--option-control-btn-hover-color));
  }

  .export:hover::after {
    background: none;
    opacity: 0;
    border-radius: inherit;
    transform: none
  }

  .export:active {
    box-shadow: none;
  }

  .pdf-toolbar {
    position: absolute;
    right: 15px;
  }
}