.status-sticker, .additionalTreatment-sticker {
    margin: 4px;
}

.status-indicator, .additionalTreatment-indicator {
    height: 48px;
    padding: 12px !important;

    strong {
        color: var(--indicator-count);
    }

    &:hover {
        cursor: pointer
    }

    &.disabled {
        cursor: not-allowed;
        background-color: var(--indicator-background-disabled);
    }
}