// @import "zindex";
@import "colors";
// @import "mixins";
@import "sz";
@import "forms";

html {
    font-size: 62.5%;
}

body {
    font-size: 1.4rem;
    background: var(--body-background);
}

html,
body,
#root {
    height: 100%;
}

#root {
    padding-top: 60px;
}

h1 {
    font-size: 2rem;
}
h2 {
    font-size: 1.5em;
}
.small {
    font-size: 1rem;
}

.h-title {
    height: calc(100% - 10.5rem);
}

.box-shadow {
    background: var(--white);
    box-shadow: 0 0 1px 0 rgba(17, 95, 251, 0.05), 0 2px 4px 0 rgba(17, 95, 251, 0.1);
    padding: 8px 16px;
}

.ptn-5 {
    top: -5px;
}

.white-space-pre-wrap {
    white-space: pre-wrap;
}

$statusList: (
    draft: "draft",
    to-qualify: "to-qualify",
    to-specify: "to-specify",
    to-plan: "to-plan",
    abandonned: "abandonned",
    planned: "planned",
    ongoing: "ongoing",
    premise-done: "premise-done",
    done: "done",
    canceled: "canceled",
    closed-rejected: "closed-rejected",
    closed: "closed"
);

$additionalTreatmentList: (
    tm-study: "tm-study",
    tm-work: "tm-work",
    exploitation-control: "exploitation-control",
);

.sz-table tbody tr {
    td:first-child::after {
        opacity: 1;
    }

    @each $key,
    $value in $statusList {
        &.request-row-#{$value} {
            position: relative;
            td:first-child {
                border-left: 5px solid var(--status-#{$value});
            }
        }
    }

    @each $key,
    $value in $additionalTreatmentList {
        &.request-row-#{$value} {
            position: relative;
            td:first-child {
                border-left: 5px solid var(--additionalTreatment-#{$value});
            }
        }
    }
}
.detail-block3 {
    .timeline-item {
        @each $key,
        $value in $statusList {
            &.timeline-#{$value} {
                .timeline-badge {
                    background-color: var(--status-#{$value});
                }
            }
        }
    }
}

.status-sticker {
    width: 12px;
    height: 12px;
    padding: 0;
    background-color: #DDD;
    border-radius: 50%;
    display: inline-block;
    vertical-align: sub;

    @each $key,
    $value in $statusList {
        &.status-sticker-#{$key} {
            background-color: var(--status-#{$value});
        }
    }
}

.additionalTreatment-sticker {
    width: 12px;
    height: 12px;
    padding: 0;
    background-color: #DDD;
    border-radius: 50%;
    display: inline-block;
    vertical-align: sub;

    @each $key,
    $value in $additionalTreatmentList {
        &.additionalTreatment-sticker-#{$key} {
            background-color: var(--additionalTreatment-#{$value});
        }
    }
}

abbr[title] {
    text-decoration: none;
}

.position-absolute-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.no-scroll {
    overflow-y: hidden;
}

.hover-underline:hover {
    text-decoration: underline;
}

.title-tooltip {
    font-size: 1.2rem;
}
