@import "src/mixins";

.detail-block1 {
    .status-sticker {
        margin-top: 34px;
    }

    .info-container {
        margin-top: 28px;
    }
}
.block1-header{
    .request-view-title{
        font-family: DINPro-Regular;
        font-size: 2rem;
        @include color(var(--primary));
    }
    .request-view-go-back{
        font-family: DINPro-Regular;
        letter-spacing: 0.5px;
    }
    .maps-icon{
        font-size: 1.6rem;
        color: var(--request-detail-address);
    }
}
