.create-user-container {
  .sz-label-input.required:after {
    right: auto;
  }

  .dropzone {
    text-align: center;
    padding-top: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: var(--light);
    border-style: dashed;
    outline: none;
    transition: border .24s ease-in-out;
    text-transform: uppercase;

    &.multi-Drop-zone {
      height: 80px;
      padding-top: 30px;
    }
  }

  .dropzone:focus {
    border-color: var(--gray-light);
  }

  .file-list {
    list-style: none;

    .delete-file {
      color: var(--red);
      padding: 2px;
      border: 1px solid var(--gray);
      cursor: pointer;
    }

  }

  .clear-date-btn {
    width: 50px;
  }

  .sz-daterangepicker_input-group-after input {
    width: 80px;
  }

  .sz-form-group {
    .input-address {
      background-color: var(--gray-0) !important;
    }
    .input-address-readonly {
      background-color: var(--toulouse-grey6) !important;
      cursor: default;

      &:focus {
        border-color: var(--input-text-border) !important;
      }
    }
  }
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background: transparent;
}
.step-actions {
  position: relative;
}
