.map-popup {
  header {
    background: var(--map-popup-header-background);
    height: 40px;
    padding: 12px 16px;

    h3 {
      font-size: 1.6rem;
      color: var(--map-popup-header-text);
    }
  }

  p {
    color: var(--map-popup-text);
    font-size: 1.4rem;
  }

  a {
    color: var(--map-popup-link);
    font-size: 1.4rem;
    &:hover {
      .sz-icon-line {
        text-decoration: none;
      }
    }
  }

  .light-text {
    color: var(--map-popup-light-text);
    font-size: 1.2rem;
    letter-spacing: 0.5px;
    line-height: 12px;
  }

  .text-intervention {
    color: var(--map-popup-text);
    font-size: 1.4rem;
  }
}
