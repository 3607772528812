@import "src/mixins";

.request-range-filter {
  .react-date-picker__wrapper {
    min-width: 180px;
  }
}

.filter-tag-box {
  .filter-tag {
    width: auto;
    font-size: 1.2rem;
    @include border-color(var(--filter-tag-border));

    &:hover {
      @include background-color(var(--filter-tag-bg-hover))
    }

    [class^='sz-icon-line'], [class*=' sz-icon-line'] {
      font-size: 1.2rem;
    }
  }
}
